import { Box, Container, Stack, Typography } from '@mui/material';
import Marquee from 'react-fast-marquee';

export default function MuzaLabels() {
  return (
    <Box
      sx={{
        minHeight: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          backgroundImage: 'url(/assets/musicians.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          filter: 'brightness(0.3)'
        }}
      ></Box>
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          height: '100%',
          opacity: 0.3,
          background: 'linear-gradient(90deg, #410d26, #74057a, #5d002c, #240426)'
        }}
      ></Box>
      <Container maxWidth="lg" sx={{ mt: 5, mb: 5, display: 'flex', flexDirection: 'column', zIndex: 5 }}>
        <Typography
          variant="h4"
          align="center"
          sx={{
            mt: 4,
            verticalAlign: 'middle',
            color: 'primary.main'
          }}
          gutterBottom
        >
          Boost your music career
        </Typography>
        <Typography variant="subtitle1" color="text.primary" align="center" gutterBottom>
          MUZA ambassadors released music on some of the biggest record labels.
        </Typography>
        <Marquee gradient={false} speed={40}>
          <Stack direction="row" spacing={4} sx={{ height: 90, my: 3 }}>
            {Array.from({ length: 18 }, (_, index) => (
              <img
                key={index}
                src={`/assets/muza-labels/${index + 1}.svg`}
                alt={`${index + 1} label`}
              />
            ))}
          </Stack>
        </Marquee>
      </Container>
    </Box>
  );
}
