import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Pagination
} from '@mui/material';
import { SearchService } from '../../client';
import Typography from '@mui/material/Typography';
import { Loader } from '../../components/Loader';
import ArtistRow from '../../components/Search/ArtistRow';
import TrackRow from '../../components/Track/TrackRow';
import BreadcrumbsPanel from '../../components/BreadcrumbsPanel';
import SearchInput from '../../components/SearchInput';

const menuItems = [
  { to: '/search', label: 'All' },
  { to: '/search/tracks', label: 'Tracks' },
  { to: '/search/artists', label: 'Artists' }
];
const LIMIT = 5;

export default function Search() {
  const { searchType } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const activeMenuLabel = menuItems.find((item) => item.to === location.pathname)?.label || 'All';
  const {
    data: results,
    isLoading,
    isRefetching
  } = useQuery(
    ['searchResults', searchParams.get('q'), searchType, page, LIMIT],
    () => SearchService.searchApiSearchGet(searchParams.get('q') || '', searchType, page, LIMIT),
    {
      keepPreviousData: true
    }
  );

  const handleChangePage = (event: React.ChangeEvent<unknown> | null, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 3, mb: 14 }}>
      <Box
        component="aside"
        sx={{
          position: 'fixed',
          width: '300px',
          height: '100vh',
          display: { xs: 'none', md: 'block' }
        }}
      >
        <List sx={{ mt: 2 }}>
          {menuItems.map((item) => (
            <ListItem disablePadding key={item.to}>
              <ListItemButton
                component={Link}
                to={`${item.to}?q=${searchParams.get('q') || ''}`}
                selected={
                  item.label.toLowerCase() === searchType || (item.label === 'All' && !searchType)
                }
              >
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
        <Grid item xs={12} sx={{ minHeight: 660 }}>
          {isLoading || isRefetching ? (
            <Box
              component="main"
              sx={{ width: { md: 'calc(100% - 300px)' }, ml: { md: '300px' }, mt: 6, px: 4 }}
            >
              <Loader />
            </Box>
          ) : (
            <Box
              component="main"
              sx={{ width: { md: 'calc(100% - 300px)' }, ml: { md: '300px' }, px: 4 }}
            >
              <BreadcrumbsPanel
                breadcrumbs={[{ label: `Search ${activeMenuLabel}`, href: location.pathname }]}
              />
              <Box sx={{ flexGrow: 0, my: 2, display: { md: 'none' } }}>
                <SearchInput />
              </Box>
              <Typography variant="h4" gutterBottom>
                Search results for "{searchParams.get('q')}"
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 8 }}>
                {results?.items.length === 0 ? (
                  <Typography variant="h6" color="text.secondary">
                    No results found
                  </Typography>
                ) : (
                  <Typography variant="h6" color="text.secondary">
                    {results?.total} results found
                  </Typography>
                )}
                {results?.items.map((result) =>
                  result.type === 'track' ? (
                    <TrackRow key={`track-${result?.id}`} track={result} />
                  ) : (
                    <ArtistRow key={`user-${result?.id}`} artist={result} />
                  )
                )}
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Pagination
              sx={{ mb: 4, ml: { md: '300px' } }}
              count={results?.pages || 0}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              color="secondary"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
