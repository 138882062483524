import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';

import { TrackListResponse, TracksService } from '../../client';
import { useQuery } from 'react-query';
import { Loader } from '../Loader';
import { Box, Skeleton } from '@mui/material';
import { useState } from 'react';
import FeaturedTrack from './FeaturedTrack';
import { usePlaylist } from '../../hooks/usePlaylist';
import { PlaylistItem } from '../CentralizedAudio/PlayerWrapper';

const swiperStyles = {
  '--swiper-navigation-color': '#fff',
  '--swiper-navigation-top-offset': '40%',
  '--swiper-navigation-size': '44px',
  '--swiper-navigation-sides-offset': '10px',

  '--swiper-pagination-color': '#fff',
  '--swiper-pagination-bullet-active-color': '#fff',
  '--swiper-pagination-bullet-size': '10px',
  '--swiper-pagination-bullet-inactive-color': '#ffffff3f',
  '--swiper-pagination-bullet-opacity': 1,
  '--swiper-pagination-bullet-inactive-opacity': 1,
  '--swiper-pagination-fraction-color': '#000',
  width: '100%',
  height: '100%'
};

export default function FeaturedTracks() {
  const [hoveredSlide, setHoveredSlide] = useState<number | null>(null);
  const { data: mostPopularTracks, isLoading } = useQuery(
    'mostPopularTracks',
    TracksService.getMostPopularTracksApiTracksGetMostPopularTracksGet,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  );

  const {
    setPlaylist,
    setCurrentTrack,
    currentTrack,
    playlist,
    onPlayPause,
    convertToPlaylistItem
  } = usePlaylist();

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{ borderRadius: 17, maxWidth: 763, height: { xs: 450, md: 500 }, width: '100%' }}
      />
    );
  }

  const handleSlideMouseOver = (trackId: number) => {
    setHoveredSlide(trackId);
  };

  const handleSlideMouseOut = () => {
    setHoveredSlide(null);
  };

  const handlePlayPause = (selectedTrack: TrackListResponse) => {
    let playListItem = playlist.find((track) => track.id === selectedTrack.id);

    if (selectedTrack.id !== currentTrack?.id) {
      const newPlayList = mostPopularTracks?.map((track) => convertToPlaylistItem(track)) || [];
      playListItem = newPlayList.find((track) => track.id === selectedTrack.id) || newPlayList[0];

      setPlaylist(newPlayList);
      setCurrentTrack(playListItem);
    }
    setTimeout(() => {
      if (playListItem) {
        onPlayPause(playListItem);
      }
    }, 300);
  };

  return (
    <Box display="flex" width="100%">
      <Swiper
        style={swiperStyles}
        navigation={true}
        pagination={{
          clickable: true
        }}
        autoplay={{ delay: 6000, pauseOnMouseEnter: true }}
        effect={'fade'}
        modules={[Autoplay, Pagination, Navigation, EffectFade]}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper: SwiperClass) => console.log(swiper)}
      >
        {mostPopularTracks?.map((track, key) => (
          <SwiperSlide
            style={{ cursor: 'pointer', userSelect: 'none' }}
            onMouseOver={() => handleSlideMouseOver(track.id)}
            onMouseOut={handleSlideMouseOut}
            key={key}
          >
            <Box
              sx={{
                width: '100%',
                height: { xs: '100%', md: 500 },
                aspectRatio: '1/1',
                borderRadius: '32px',
                overflow: 'hidden'
              }}
            >
              <FeaturedTrack
                key={key}
                track={track}
                active={hoveredSlide === track.id}
                onPlayPause={() => handlePlayPause(track)}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
