import React, { FormEventHandler, useState } from 'react';
import { Button, TextField, Typography, Container, Box, Grid, Paper, Link } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthService, LoginForm, LoginResponse } from '../client';
import MuzaLogo from '../components/MuzaLogo';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  let [searchParams] = useSearchParams();

  const redirectPath = searchParams.get('next') || '/';

  const loginMutation = useMutation<LoginResponse, Error, LoginForm>(
    AuthService.loginApiAuthLoginPost,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['currentUser']);
        queryClient.invalidateQueries(['profile']);
      }
    }
  );

  const handleLogin: FormEventHandler = async (event) => {
    event.preventDefault();
    try {
      await loginMutation.mutateAsync({ email, password });
      navigate(redirectPath);
    } catch (error) {
      // Handle error, e.g., show error message
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: 15
      }}
    >
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography component="h1" variant="h5" sx={{ display: 'flex', gap: 1 }}>
          Log in to <MuzaLogo />
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleLogin}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} size="large">
            Sign In
          </Button>
          {loginMutation.isError ? <Typography color="error">Error logging in.</Typography> : null}
          <Grid container>
            <Grid item xs>
              <Typography variant="subtitle1" gutterBottom>
                Don't have an account? <Link href={'/signup'} underline="none">Sign up</Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" underline="none">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
        {/*<Button fullWidth variant="outlined" startIcon={<Google />} sx={{ mt: 3, mb: 2 }}>*/}
        {/*  Sign in with Google*/}
        {/*</Button>*/}
      </Paper>
    </Container>
  );
}
