import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import { Container, IconButton, Stack, Typography } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';

export default function Footer() {
  return (
    <Box
      sx={{
        borderTop: '1px solid #ffffff1f',
        display: 'flex',
        flexDirection: 'row',
        paddingY: 5,
        justifyContent: 'space-between',
        background: 'linear-gradient(90deg, #410d26, #74057a, #5d002c, #240426)',
        zIndex: 1
      }}
    >
      <Container maxWidth="xl" sx={{ mb: 4 }}>
        <Stack direction="row" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 700,
                color: 'white',
                letterSpacing: '0rem',
                textDecoration: 'none',
                alignItems: 'center',
                gap: 1
              }}
            >
              <img src="/assets/muza-beta.svg" alt="Muza Logo" style={{ height: 40 }} />
            </Typography>
            <NavLink to="/terms" style={{ textDecoration: 'none' }}>
              <Typography
                variant="subtitle2"
                color="text.primary"
                sx={{
                  ':hover': {
                    color: 'text.secondary',
                    transition: '0.3s'
                  }
                }}
              >
                Terms of Service
              </Typography>
            </NavLink>
            <NavLink
              to="/privacy"
              style={{
                textDecoration: 'none'
              }}
            >
              <Typography
                variant="subtitle2"
                color="text.primary"
                sx={{
                  ':hover': {
                    color: 'text.secondary',
                    transition: '0.3s'
                  }
                }}
              >
                Privacy Policy
              </Typography>
            </NavLink>
            <NavLink
              to="/pricing"
              style={{
                textDecoration: 'none'
              }}
            >
              <Typography
                variant="subtitle2"
                color="text.primary"
                sx={{
                  ':hover': {
                    color: 'text.secondary',
                    transition: '0.3s'
                  }
                }}
              >
                Subscription Plans
              </Typography>
            </NavLink>
            <Typography variant="caption" color="text.secondary" sx={{ mt: 3 }}>
              © 2024 MUZA.Studio All Rights Reserved
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h6" color="text.primary">
              Connect with Us
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              <a href="mailto:support@muza.studio" rel="noreferrer" target="_blank">
                <Typography variant="subtitle2" color="text.primary">
                  support@muza.studio
                </Typography>
              </a>
              <Stack direction="row" gap={0.5}>
                <IconButton
                  href="https://www.instagram.com/muzacollab?igsh=MXJqcGE0cGtkcGo0dw=="
                  target="_blank"
                >
                  <Instagram color="secondary" sx={{ fontSize: 28 }} />
                </IconButton>
                <IconButton
                  href="https://www.facebook.com/profile.php?id=61566506756915"
                  target="_blank"
                >
                  <Facebook color="secondary" sx={{ fontSize: 28 }} />
                </IconButton>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
